import * as color from "../styles/color";
import * as executionElementType from "../enums/executionElementType";
import CampaignExecutionNewElement from "./CampaignExecutionNewElement";
import DeleteIcon from "./assets/delete-icon.svg";
import generateSrcSet from "../lib/generateSrcSet";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import UploadIcon from "./assets/upload-icon.svg";
import { css, StyleSheet } from "aphrodite";
import { Button, Dropdown, MenuItem } from "react-bootstrap";

const styles = StyleSheet.create({
    elementContainer: {
        position: "relative",
        width: "calc((100% - 32px) / 3)",
        minWidth: "calc((100% - 32px) / 3)",
        maxWidth: "calc((100% - 32px) / 3)",
        border: "1px solid",
        borderColor: color.lightGrey,
        borderRadius: "4px",
        padding: "24px",
    },
    deletedElementContainer: {
        borderColor: color.red,
    },
    replacingElementContainer: {
        borderColor: color.blue,
    },
    thumbnail: {
        width: "100%",
        maxHeight: "200px",
        objectFit: "contain",
        borderRadius: "4px",
        marginBottom: "12px",
        backgroundColor: color.black,
    },
    nonRemoveButtonsContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
        marginBottom: "8px",
    },
    button: {
        display: "flex",
        alignItems: "center",
        padding: "4px",
        gap: "8px",
        border: "initial",
        background: "initial",
        boxShadow: "initial",
        fontWeight: "500",
        color: color.blue,
    },
    downloadIcon: {
        transform: "rotate(180deg)",
    },
    deleteButton: {
        color: color.red,
    },
    deletionContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: color.redRgb5,
    },
    cancelDeletionButton: {
        fontWeight: "500",
        color: color.red,
    },
    replaceContainer: {
        border: "none",
        width: "initial",
        minWidth: "initial",
        maxWidth: "initial",
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: "40px",
        paddingBottom: 0,
    },
});

const strings = {
    replaceButtonLabel: "Replace",
    downloadButtonLabel: "Download",
    deleteButtonLabel: "Delete",
    replaceIconAccessibility: "Replace element",
    downloadIconAccessibility: "Download element",
    deleteIconAccessibility: "Delete element",
    cancelDeleteButtonLabel: "Cancel",
    imageScalePrefix: "Scale: ",
    imageScaleSuffix: "x",
};

class CampaignExecutionElement extends PureComponent {
    static propTypes = {
        element: PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            data: PropTypes.object.isRequired,
        }).isRequired,
        requirements: PropTypes.shape({
            image: PropTypes.shape({
                height: PropTypes.number,
                width: PropTypes.number,
            }),
            video: PropTypes.shape({
                height: PropTypes.number,
                width: PropTypes.number,
            }),
        }),
        disabled: PropTypes.bool,
        replaceElement: PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            data: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }),
        onReplace: PropTypes.func.isRequired,
        onUpdateReplace: PropTypes.func.isRequired,
        onCancelReplace: PropTypes.func.isRequired,
        isDeleted: PropTypes.bool,
        onDelete: PropTypes.func.isRequired,
        onCancelDelete: PropTypes.func.isRequired,
    };

    _handleClickReplace = () => {
        this.props.onReplace({
            ...this.props.element,
            data: {
                name: this.props.element.data.name,
            },
        });
    };

    _handleUpdateReplaceData = (_, element) => {
        this.props.onUpdateReplace(element);
    };

    _handleCancelReplace = () => {
        this.props.onCancelReplace(this.props.element.id);
    };

    _onSelectDownloadImageByIndex = i => {
        const image = this.props.element.data.images[i];
        this._downloadUrl(
            image.url,
            `${this.props.element.data.name}-${image.scale}${strings.imageScaleSuffix}`,
        );
    };

    _handleClickDownloadVideo = () => {
        this._downloadUrl(this.props.element.data.url);
    };

    // Does not actually consistently directly download file, takes you to AWS storage location
    _downloadUrl = (url, name) => {
        const downloadLinkElement = document.createElement("a");

        downloadLinkElement.href = url;
        downloadLinkElement.download = name ? name : this.props.element.data.name;
        downloadLinkElement.target = "_blank";

        document.body.appendChild(downloadLinkElement);
        downloadLinkElement.click();
        document.body.removeChild(downloadLinkElement);
    };

    _handleClickDelete = () => {
        this.props.onDelete(this.props.element);
    };

    _handleClickCancelDelete = () => {
        this.props.onCancelDelete(this.props.element.id);
    };

    render() {
        return (
            <div
                className={css(
                    styles.elementContainer,
                    this.props.isDeleted && styles.deletedElementContainer,
                    this.props.replaceElement && styles.replacingElementContainer,
                )}
            >
                <h4>{this.props.element.data.name}</h4>
                {!this.props.replaceElement ? (
                    <>
                        {this.props.element.type === executionElementType.image ? (
                            <img
                                className={css(styles.thumbnail)}
                                alt={this.props.element.data.name}
                                srcSet={generateSrcSet(this.props.element.data.images)}
                            />
                        ) : null}
                        {this.props.element.type === executionElementType.video ? (
                            <video
                                className={css(styles.thumbnail)}
                                src={this.props.element.data.url}
                                type="video/mp4"
                                controls
                                preload="none"
                            />
                        ) : null}
                        <div className={css(styles.nonRemoveButtonsContainer)}>
                            <Button
                                className={css(styles.button)}
                                disabled={this.props.disabled || this.props.isDeleted}
                                onClick={this._handleClickReplace}
                            >
                                <img alt={strings.replaceIconAccessibility} src={UploadIcon} />
                                {strings.replaceButtonLabel}
                            </Button>
                            {this.props.element.type === executionElementType.image ? (
                                <Dropdown
                                    id="download-image-dropdown"
                                    title={strings.downloadButtonLabel}
                                    disabled={this.props.disabled || this.props.isDeleted}
                                >
                                    <Dropdown.Toggle className={css(styles.button)}>
                                        <img
                                            className={css(styles.downloadIcon)}
                                            alt={strings.downloadIconAccessibility}
                                            src={UploadIcon}
                                        />
                                        {strings.downloadButtonLabel}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.props.element.data.images.map((image, i) => (
                                            <MenuItem
                                                key={image.id}
                                                eventKey={i}
                                                onSelect={this._onSelectDownloadImageByIndex}
                                            >
                                                {`${strings.imageScalePrefix}${image.scale}${strings.imageScaleSuffix}`}
                                            </MenuItem>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : null}
                            {this.props.element.type === executionElementType.video ? (
                                <Button
                                    className={css(styles.button)}
                                    disabled={this.props.disabled || this.props.isDeleted}
                                    onClick={this._handleClickDownloadVideo}
                                >
                                    <img
                                        className={css(styles.downloadIcon)}
                                        alt={strings.downloadIconAccessibility}
                                        src={UploadIcon}
                                    />
                                    {strings.downloadButtonLabel}
                                </Button>
                            ) : null}
                        </div>
                        <Button
                            className={css(styles.button, styles.deleteButton)}
                            onClick={this._handleClickDelete}
                            disabled={this.props.disabled || this.props.isDeleted}
                        >
                            <img alt={strings.deleteIconAccessibility} src={DeleteIcon} />
                            {strings.deleteButtonLabel}
                        </Button>
                        {this.props.isDeleted ? (
                            <div className={css(styles.deletionContainer)}>
                                <Button
                                    className={css(styles.cancelDeletionButton)}
                                    onClick={this._handleClickCancelDelete}
                                    disabled={this.props.disabled}
                                >
                                    {strings.cancelDeleteButtonLabel}
                                </Button>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <CampaignExecutionNewElement
                        className={styles.replaceContainer}
                        index={-1}
                        element={this.props.replaceElement}
                        requirements={this.props.requirements}
                        disabled={this.props.disabled}
                        hideNameInput
                        onUpdateData={this._handleUpdateReplaceData}
                        onRemove={this._handleCancelReplace}
                    />
                )}
            </div>
        );
    }
}

export default CampaignExecutionElement;
