/* eslint-disable react/jsx-no-bind */

import * as color from "../../styles/color";
import * as contests from "../../lib/api/contests";
import * as landingPages from "../../lib/landingPages";
import * as util from "../../lib/util";
import AlertModal from "../AlertModal";
import DuplicateLandingPageModal from "../DuplicateLandingPageModal";
import FailedToLoadAlert from "../FailedToLoadAlert";
import PropTypes from "prop-types";
import LandingPageCrumbEditHeader from "../LandingPageCrumbEditHeader";
import Loader from "../Loader";
import logger from "../../lib/logger";
import React, { PureComponent } from "react";
import TextInputWithDuplicateCheck from "../TextInputWithDuplicateCheck";
import {
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Row,
} from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    loadingContainer: {
        position: "absolute",
        top: "50px",
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        display: "flex",
        alignItems: "center",
    },
    textArea: {
        minHeight: "30px",
        width: "100%",
    },
    slugFormGroup: {
        marginBottom: "4px",
    },
    slugHelpText: {
        marginBottom: 0,
    },
    copySlugContainer: {
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    fullLandingPageUrl: {
        marginRight: "12px",
    },
    copyUrlButton: {
        padding: 0,
    },
    isActiveCheckbox: {
        marginBottom: "100px",
    },
    errorText: {
        color: color.red,
    },
});

const strings = {
    newContestSlug: "new",
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    contestPages: "Contest Pages",
    newContest: "New Contest",
    contest: "Contest",
    contestUrl: "contest",
    contestsUrl: "/contests",
    available: "Available",
    generalErrorMsg: "There was an error with your request.",
    name: "Name",
    namePlaceholder: "Enter name",
    headerText: "Header Text",
    headerTextPlaceholder: "Enter header text",
    successHeaderText: "Success Header Text",
    successHeaderTextPlaceholder: "Enter success header text",
    paragraphText: "Paragraph Text",
    paragraphTextPlaceholder: "Enter paragraph text",
    successParagraphText: "Success Paragraph Text",
    successParagraphTextPlaceholder: "Enter success paragraph text",
    slug: "URL Slug",
    slugPlaceholder: "Enter URL slug",
    copyUrl: "Copy URL",
    activeCheckboxLabel: "Active",
    fieldInUse: fieldName =>
        `This ${fieldName} is already in use. Select a different ${fieldName}.`,
};

class ContestPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    state = {
        isLoading: true,
        showAlertModal: false,
        contest: null,
        showDuplicateModal: false,
        inUseNames: new Set(),
        inUseSlugs: new Set(),
        name: "",
        headerText: "",
        paragraphText: "",
        successHeaderText: "",
        successParagraphText: "",
        slug: "",
        isActive: false,
        duplicateName: "",
        duplicateSlug: "",
        errorMsg: "",
    };

    _isNewContest = () => {
        return this.props.match.params.contestId === strings.newContestSlug;
    };

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;

        if (!this._isMounted) {
            return;
        }

        const locationState = this.props.location.state;
        const contest = locationState ? locationState.contest : undefined;
        const inUseNames = locationState ? locationState.inUseNames : undefined; // If this field is missing, then the inUseNames state will remain an empty set
        const inUseSlugs = locationState ? locationState.inUseSlugs : undefined; // If this field is missing, then the inUseSlugs state will remain an empty set

        if (!contest && !this._isNewContest()) {
            this.setState({ isLoading: false });
            return;
        }

        if (contest) {
            this.setState({
                name: contest.name,
                headerText: contest.headerText,
                successHeaderText: contest.successHeaderText,
                paragraphText: contest.paragraphText,
                successParagraphText: contest.successParagraphText,
                slug: contest.slug,
                isActive: contest.isActive,
            });
        }

        this.setState({
            ...(!!inUseNames && { inUseNames }),
            ...(!!inUseSlugs && { inUseSlugs }),
        });

        if (this._isNewContest()) {
            document.title = `${strings.newContest} ${strings.titleSuffix}`;
            this.setState({
                isLoading: false,
            });
            return;
        }

        document.title = `${contest.name} ${strings.titleSuffix}`;
        this.setState({
            isLoading: false,
            contest,
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleSavePage = () => {
        this.setState({ isLoading: true });
        if (this.state.contest && !this._isNewContest()) {
            const editedContest = {
                ...(this.state.contest.name !== this.state.name && {
                    name: this.state.name.trim(),
                }),
                ...(this.state.contest.headerText !== this.state.headerText && {
                    headerText: this.state.headerText.trim(),
                }),
                ...(this.state.contest.successHeaderText !== this.state.successHeaderText && {
                    successHeaderText: this.state.successHeaderText.trim(),
                }),
                ...(this.state.contest.paragraphText !== this.state.paragraphText && {
                    paragraphText: this.state.paragraphText.trim(),
                }),
                ...(this.state.contest.successParagraphText !== this.state.successParagraphText && {
                    successParagraphText: this.state.successParagraphText.trim(),
                }),
                ...(this.state.contest.slug !== this.state.slug && {
                    slug: this.state.slug.trim(),
                }),
                ...(this.state.contest.isActive !== this.state.isActive && {
                    isActive: this.state.isActive,
                }),
            };
            return contests
                .editContest(this.state.contest.id, editedContest)
                .then(resp => {
                    if (this._isMounted) {
                        this.setState({ isLoading: false });
                        this.props.history.push(strings.contestsUrl);
                    }
                    return resp;
                })
                .catch(e => {
                    logger.warn(e);
                    let errorMsg = strings.generalErrorMsg;
                    if (
                        e &&
                        e.response &&
                        e.response.status === 400 &&
                        e.response.data &&
                        typeof e.response.data === "string"
                    ) {
                        errorMsg = e.response.data;
                    }
                    this.setState({ isLoading: false, showAlertModal: true, errorMsg });
                });
        }

        return contests
            .createContest({
                name: this.state.name.trim(),
                headerText: this.state.headerText.trim(),
                successHeaderText: this.state.successHeaderText.trim(),
                paragraphText: this.state.paragraphText.trim(),
                successParagraphText: this.state.successParagraphText.trim(),
                slug: this.state.slug.trim(),
                isActive: this.state.isActive,
            })
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ isLoading: false });
                    this.props.history.push(strings.contestsUrl);
                }
                return resp;
            })
            .catch(e => {
                logger.warn(e);
                let errorMsg = strings.generalErrorMsg;
                if (
                    e &&
                    e.response &&
                    e.response.status === 400 &&
                    e.response.data &&
                    typeof e.response.data === "string"
                ) {
                    errorMsg = e.response.data;
                }
                this.setState({ isLoading: false, showAlertModal: true, errorMsg });
            });
    };

    _showDuplicateModal = () => this.setState({ showDuplicateModal: true });

    _handleCloseDuplicateModal = () =>
        this.setState({ showDuplicateModal: false, duplicateName: "", duplicateSlug: "" });

    _handleDuplicate = () => {
        if (this._isMounted) {
            this.props.history.push("/contest/new", {
                contest: {
                    name: this.state.duplicateName.trim(),
                    headerText: this.state.headerText.trim(),
                    successHeaderText: this.state.successHeaderText.trim(),
                    paragraphText: this.state.paragraphText.trim(),
                    successParagraphText: this.state.successParagraphText.trim(),
                    isActive: this.state.isActive,
                    slug: this.state.duplicateSlug.trim(),
                },
                inUseNames: this.state.inUseNames,
                inUseSlugs: this.state.inUseSlugs,
            });
        }
    };

    _getNameInUse = name =>
        this.state.inUseNames.has(name) &&
        (!this.state.contest || (!!this.state.contest && this.state.contest.name !== name));
    _getIsValidName = value => !!value && !this._getNameInUse(value);
    _getSlugInUse = slug =>
        this.state.inUseSlugs.has(slug) &&
        (!this.state.contest || (!!this.state.contest && this.state.contest.slug !== slug));
    _getIsValidSlug = value => !!value && !this._getSlugInUse(value);
    _refineSlug = value => value.replace(/\s/g, "").toLowerCase();

    _getDuplicateNameInUse = value => this.state.inUseNames.has(value);
    _getIsValidDuplicateName = value => !!value && !this._getDuplicateNameInUse(value);
    _getDuplicateSlugInUse = value => this.state.inUseSlugs.has(value);
    _getIsValidDuplicateSlug = value => !!value && !this._getDuplicateSlugInUse(value);

    render() {
        if (!this.state.contest && !this._isNewContest()) {
            return <FailedToLoadAlert type={strings.contest} />;
        }

        const contest = this.state.contest;

        const isInputValid =
            this._getIsValidName(this.state.name.trim()) &&
            !!this.state.headerText.trim() &&
            !!this.state.successHeaderText.trim() &&
            !!this.state.paragraphText.trim() &&
            !!this.state.successParagraphText.trim() &&
            this._getIsValidSlug(this.state.slug.trim());

        const isChanged =
            !!contest &&
            (contest.name !== this.state.name.trim() ||
                contest.headerText !== this.state.headerText.trim() ||
                contest.successHeaderText !== this.state.successHeaderText.trim() ||
                contest.paragraphText !== this.state.paragraphText.trim() ||
                contest.successParagraphText !== this.state.successParagraphText.trim() ||
                contest.slug !== this.state.slug.trim() ||
                contest.isActive !== this.state.isActive);

        const isDuplicateModalEnabled =
            !this.state.isLoading && !this._isNewContest() && !isChanged;
        const isSavePageEnabled =
            !this.state.isLoading && isInputValid && (isChanged || this._isNewContest());

        const isDuplicateEnabled =
            this._getIsValidDuplicateName(this.state.duplicateName.trim()) &&
            this._getIsValidDuplicateSlug(this.state.duplicateSlug.trim());

        const fullLandingPageUrl = landingPages.getFullLandingPageUrl(
            strings.contestUrl,
            this.state.slug.trim(),
        );

        return (
            <Grid>
                <LandingPageCrumbEditHeader
                    history={this.props.history}
                    landingPagesLinkText={strings.contestPages}
                    landingPagesLinkUrl={strings.contestsUrl}
                    pageTitle={contest ? contest.name : strings.newContest}
                    isDuplicateEnabled={isDuplicateModalEnabled}
                    onDuplicate={this._showDuplicateModal}
                    isSavePageEnabled={isSavePageEnabled}
                    onSavePage={this._handleSavePage}
                />
                <form>
                    <Row>
                        <Col md={7}>
                            <TextInputWithDuplicateCheck
                                label={strings.name}
                                placeholder={strings.namePlaceholder}
                                value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })}
                                inUseValues={this.state.inUseNames}
                                currentValue={
                                    this.state.contest ? this.state.contest.name : ""
                                }
                                isValid={this._getIsValidName(this.state.name)}
                                disabled={this.state.isLoading}
                            />
                            <FormGroup controlId="header-text">
                                <ControlLabel>{strings.headerText}</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.headerText}
                                    placeholder={strings.headerTextPlaceholder}
                                    onChange={e => this.setState({ headerText: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup controlId="success-header-text">
                                <ControlLabel>{strings.successHeaderText}</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.successHeaderText}
                                    placeholder={strings.successHeaderTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ successHeaderText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <FormGroup controlId="paragraph-text">
                                <ControlLabel>{strings.paragraphText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.paragraphText}
                                    placeholder={strings.paragraphTextPlaceholder}
                                    onChange={e => this.setState({ paragraphText: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup controlId="success-paragraph-text">
                                <ControlLabel>{strings.successParagraphText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.successParagraphText}
                                    placeholder={strings.successParagraphTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ successParagraphText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <TextInputWithDuplicateCheck
                                style={styles.slugFormGroup}
                                helpTextStyle={styles.slugHelpText}
                                label={strings.slug}
                                placeholder={strings.slugPlaceholder}
                                value={this.state.slug}
                                onChange={e =>
                                    this.setState({
                                        slug: this._refineSlug(e.target.value),
                                    })
                                }
                                inUseValues={this.state.inUseSlugs}
                                currentValue={
                                    this.state.contest ? this.state.contest.slug : ""
                                }
                                isValid={this._getIsValidSlug(this.state.slug)}
                                disabled={this.state.isLoading}
                            />
                            <div className={css(styles.copySlugContainer)}>
                                <span className={css(styles.fullLandingPageUrl)}>
                                    {fullLandingPageUrl}
                                </span>
                                <Button
                                    bsStyle={"link"}
                                    className={css(styles.copyUrlButton)}
                                    onClick={() => util.copyToClipboard(fullLandingPageUrl)}
                                >
                                    {strings.copyUrl}
                                </Button>
                            </div>
                            <FormGroup>
                                <Checkbox
                                    className={css(styles.isActiveCheckbox)}
                                    checked={this.state.isActive}
                                    onChange={() =>
                                        this.setState({
                                            isActive: !this.state.isActive,
                                        })
                                    }
                                    disabled={this.state.isLoading}
                                >
                                    {strings.activeCheckboxLabel}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
                <DuplicateLandingPageModal
                    show={this.state.showDuplicateModal}
                    itemName={strings.contest}
                    onClose={this._handleCloseDuplicateModal}
                    onDuplicate={this._handleDuplicate}
                    duplicateDisabled={!isDuplicateEnabled}
                >
                    <TextInputWithDuplicateCheck
                        label={strings.name}
                        placeholder={strings.namePlaceholder}
                        value={this.state.duplicateName}
                        onChange={e => this.setState({ duplicateName: e.target.value })}
                        inUseValues={this.state.inUseNames}
                        isValid={this._getIsValidDuplicateName(this.state.duplicateName)}
                        disabled={this.state.isLoading}
                    />
                    <TextInputWithDuplicateCheck
                        helpTextStyle={styles.slugHelpText}
                        label={strings.slug}
                        placeholder={strings.slugPlaceholder}
                        value={this.state.duplicateSlug}
                        onChange={e =>
                            this.setState({
                                duplicateSlug: this._refineSlug(e.target.value),
                            })
                        }
                        inUseValues={this.state.inUseSlugs}
                        isValid={this._getIsValidDuplicateSlug(this.state.duplicateSlug)}
                        disabled={this.state.isLoading}
                    />
                </DuplicateLandingPageModal>
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={this.state.errorMsg}
                    handleClose={() => this.setState({ showAlertModal: false, errorMsg: "" })}
                />
                {this.state.isLoading ? (
                    <div className={css(styles.loadingContainer)}>
                        <Loader />
                    </div>
                ) : null}
            </Grid>
        );
    }
}

export default ContestPage;
