import AlertModal from "./AlertModal";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import StyledDropdown from "./StyledDropdown";
import { Modal, Button, FormGroup, ControlLabel, Checkbox } from "react-bootstrap";

const strings = {
    modalTitle: "Assign Campaign",
    dropdownLabel: "Select Campaign",
    dropdownPlaceholder: "Select a campaign",
    defaultCheckboxLabel: "Apply as Default",
    cancel: "Cancel",
    confirmPromptLabel: "Assign to Stores",
    defaultDateAlertMsg:
        "The selected campaign has a start or end date and can’t be assigned as a default campaign.",
};

export default class AssignCampaignModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        campaigns: PropTypes.arrayOf(
            PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired }),
        ).isRequired,
        handleClose: PropTypes.func.isRequired,
        handleAssignCampaign: PropTypes.func.isRequired,
    };

    state = {
        selectedCampaign: null,
        isDefault: false,
        showAlertModal: false,
    };

    _checkValidDefaultCampaign = selectedCampaign => {
        return !(selectedCampaign && (selectedCampaign.startDate || selectedCampaign.endDate));
    };

    _handleChangeCampaignDropdown = value => {
        const selectedCampaign = this.props.campaigns.find(campaign => campaign.name === value);
        this.setState(prevState => {
            const newIsInvalidDefaultCampaignDate = !this._checkValidDefaultCampaign(
                selectedCampaign,
                prevState.isDefault,
            );

            return {
                selectedCampaign,
                showAlertModal: newIsInvalidDefaultCampaignDate,
            };
        });
    };

    _handleChangeDefaultCheckbox = () => {
        this.setState(prevState => ({ isDefault: !prevState.isDefault }));
    };

    _handleExited = () => {
        this.setState({ selectedCampaign: null, isDefault: false, showAlertModal: false });
    };

    _handleClickConfirm = () => {
        this.props.handleAssignCampaign(this.state.selectedCampaign.id, this.state.isDefault);
    };

    _hideAlertModal = () => {
        this.setState({
            showAlertModal: false,
        });
    };

    render() {
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    onExited={this._handleExited}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{strings.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup controlId="campaign">
                            <ControlLabel>{strings.dropdownLabel}</ControlLabel>
                            <StyledDropdown
                                id="campaign"
                                placeholder={strings.dropdownPlaceholder}
                                value={
                                    this.state.selectedCampaign
                                        ? this.state.selectedCampaign.name
                                        : ""
                                }
                                onChange={this._handleChangeCampaignDropdown}
                                options={[
                                    ...this.props.campaigns.map(campaign => ({
                                        value: campaign.name,
                                    })),
                                ]}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox
                                checked={this.isDefault}
                                onChange={this._handleChangeDefaultCheckbox}
                                disabled={
                                    !this.state.selectedCampaign ||
                                    !this._checkValidDefaultCampaign(this.state.selectedCampaign)
                                }
                            >
                                {strings.defaultCheckboxLabel}
                            </Checkbox>
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.handleClose}>{strings.cancel}</Button>
                        <Button bsStyle="primary" onClick={this._handleClickConfirm}>
                            {strings.confirmPromptLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={strings.defaultDateAlertMsg}
                    handleClose={this._hideAlertModal}
                />
            </>
        );
    }
}
