import * as campaignType from "../enums/campaignType";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import StyledDropdown from "./StyledDropdown";
import { Button, ControlLabel, FormGroup, Modal } from "react-bootstrap";

const strings = {
    title: "Unassign Campaign from Stores",
    dropdownLabel: "Campaign Type",
    cancelButtonLabel: "Cancel",
    confirmButtonLabel: "Remove Campaign",
    noCampaignsText: selectedCampaignType =>
        `No ${selectedCampaignType} campaigns can be removed with your current selection.`,
    confirmText: (selectedCampaignType, numOfAssignments) =>
        `Removing this ${selectedCampaignType} campaign will remove ${numOfAssignments} currently assigned campaign(s), are you sure you want to proceed?`,
};

export default class RemoveCampaignModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onRemoveCampaign: PropTypes.func.isRequired,
        stores: PropTypes.arrayOf(
            PropTypes.shape({
                activeCampaignId: PropTypes.string,
                defaultCampaignId: PropTypes.string,
            }),
        ).isRequired,
    };

    state = {
        removeCampaignType: campaignType.activeCampaign.value,
    };

    _handleExited = () => {
        this.setState({ removeCampaignType: campaignType.activeCampaign.value });
    };

    _handleChangeDropdown = value => {
        this.setState({ removeCampaignType: value });
    };

    _handleClickRemoveCampaign = () => {
        this.props.onRemoveCampaign(this.state.removeCampaignType);
    };

    render() {
        const numOfRemovableCampaigns = this.props.stores.reduce(
            (storeCount, store) =>
                (this.state.removeCampaignType === campaignType.activeCampaign.value &&
                    store.activeCampaignId) ||
                (this.state.removeCampaignType === campaignType.defaultCampaign.value &&
                    store.defaultCampaignId)
                    ? storeCount + 1
                    : storeCount,
            0,
        );
        const enableRemove = numOfRemovableCampaigns > 0;

        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.onClose}
                    onExited={this._handleExited}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{strings.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup controlId="campaign-type">
                            <ControlLabel>{strings.dropdownLabel}</ControlLabel>
                            <StyledDropdown
                                id="campaign-type"
                                placeholder={campaignType.activeCampaign.value}
                                value={this.state.removeCampaignType}
                                onChange={this._handleChangeDropdown}
                                options={[
                                    campaignType.activeCampaign,
                                    campaignType.defaultCampaign,
                                ]}
                            />
                        </FormGroup>
                        {enableRemove
                            ? strings.confirmText(
                                  this.state.removeCampaignType,
                                  numOfRemovableCampaigns,
                              )
                            : strings.noCampaignsText(this.state.removeCampaignType)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.onClose}>{strings.cancelButtonLabel}</Button>
                        <Button
                            bsStyle="danger"
                            disabled={!enableRemove}
                            onClick={this._handleClickRemoveCampaign}
                        >
                            {strings.confirmButtonLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
