import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, Modal } from "react-bootstrap";

const strings = {
    cancel: "Cancel",
    yes: "Yes",
};

export default class ConfirmationModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        prompt: PropTypes.string,
        confirmButtonBsStyle: PropTypes.string,
        confirmButtonLabel: PropTypes.string,
        handleCancel: PropTypes.func.isRequired,
        handleConfirm: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.prompt}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button onClick={this.props.handleCancel}>{strings.cancel}</Button>
                    <Button
                        bsStyle={
                            this.props.confirmButtonBsStyle
                                ? this.props.confirmButtonBsStyle
                                : "primary"
                        }
                        onClick={this.props.handleConfirm}
                    >
                        {this.props.confirmButtonLabel
                            ? this.props.confirmButtonLabel
                            : strings.yes}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
