/* eslint-disable react/jsx-no-bind */

import * as color from "../../styles/color";
import * as landingPages from "../../lib/landingPages";
import * as nros from "../../lib/api/nros";
import * as util from "../../lib/util";
import React, { PureComponent } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Checkbox,
    Button,
} from "react-bootstrap";
import ImageSetUploader from "../ImageSetUploader";
import PropTypes from "prop-types";
import DuplicateLandingPageModal from "../DuplicateLandingPageModal";
import logger from "../../lib/logger";
import Loader from "../Loader";
import FailedToLoadAlert from "../FailedToLoadAlert";
import AlertModal from "../AlertModal";
import LandingPageCrumbEditHeader from "../LandingPageCrumbEditHeader";
import StyledDropdown from "../StyledDropdown";
import TextInputWithDuplicateCheck from "../TextInputWithDuplicateCheck";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    loadingContainer: {
        position: "absolute",
        top: "50px",
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        display: "flex",
        alignItems: "center",
    },
    textArea: {
        minHeight: "80px",
        width: "100%",
    },
    imageUploadContainer: {
        border: "1px solid #DADADA",
        borderRadius: "4px",
        marginBottom: "16px",
        overflow: "hidden",
    },
    slugFormGroup: {
        marginBottom: "4px",
    },
    slugHelpText: {
        marginBottom: 0,
    },
    copySlugContainer: {
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    fullLandingPageUrl: {
        marginRight: "12px",
    },
    copyUrlButton: {
        padding: 0,
    },
    isActiveCheckbox: {
        marginBottom: "100px",
    },
    storeNumberDropdownMenu: {
        maxHeight: "300px",
    },
    errorText: {
        color: color.red,
    },
});

const strings = {
    newNROSlug: "new",
    loading: "Loading",
    titleSuffix: "| Habit CMS",
    nroLandingPages: "NRO Landing Pages",
    newNRO: "New NRO",
    nro: "NRO",
    nroUrl: "nro",
    nrosUrl: "/nros",
    available: "Available",
    generalErrorMsg: "There was an error with your request.",
    storeOptionsErrorMsg: "No store number options could be retrieved.",
    nroName: "NRO Page Name",
    nroNamePlaceholder: "Enter NRO page name",
    storeNumber: "Store Number",
    storeNumberPlaceholder: "Pick a store number",
    storeName: "Store Name",
    storeAddress: "Store Address",
    headerText: "Header Text",
    headerTextPlaceholder: "Enter header text",
    registerParagraphText: "Registration Text",
    registerParagraphTextPlaceholder: "Enter registration text",
    loginParagraphText: "Login Text",
    loginParagraphTextPlaceholder: "Enter login text",
    loggedInParagraphText: "Logged-In Text",
    loggedInParagraphTextPlaceholder: "Enter logged in text",
    completionText: "Completion Text",
    completionTextPlaceholder: "Enter completion text",
    slug: "URL Slug",
    slugPlaceholder: "Enter URL slug",
    copyUrl: "Copy URL",
    activeCheckboxLabel: "Active",
    desktopImageLabel: "Hero Image Desktop",
    tabletImageLabel: "Hero Image Tablet",
    mobileImageLabel: "Hero Image Mobile",
    fieldInUse: fieldName =>
        `This ${fieldName} is already in use. Select a different ${fieldName}.`,
};

/*

image state fields: {image, centerX, centerY, width, height, imageData} - the first 5 fields need to be submitted

*/

class NROPage extends PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    state = {
        isLoading: true,
        showAlertModal: false,
        nro: null,
        showDuplicateModal: false,
        inUseNames: new Set(),
        inUseStoreNumbers: new Set(),
        inUseSlugs: new Set(),
        nroName: "",
        storeId: "",
        storeNumber: "",
        storeName: "",
        storeAddress: "",
        headerText: "",
        registerParagraphText: "",
        loginParagraphText: "",
        loggedInParagraphText: "",
        completionText: "",
        slug: "",
        isActive: false,
        desktopImage: null,
        tabletImage: null,
        mobileImage: null,
        storeOptions: [],
        duplicateNROName: "",
        duplicateStoreNumber: "",
        duplicateStore: null,
        duplicateSlug: "",
        errorMsg: "",
    };

    _isNewNRO = () => {
        return this.props.match.params.nroId === strings.newNROSlug;
    };

    _formatAddress = address =>
        `${address.street1}${address.street2 ? " " + address.street2 : ""} ${address.city}, ${
            address.state
        } ${address.postalCode}`;

    componentDidMount() {
        this._isMounted = true;
        document.title = `${strings.loading} ${strings.titleSuffix}`;

        if (!this._isMounted) {
            return;
        }

        const locationState = this.props.location.state;
        const nro = locationState ? locationState.nro : undefined;
        const inUseNames = locationState ? locationState.inUseNames : undefined; // If this field is missing, then the inUseNames state will remain an empty set
        const inUseStoreNumbers = locationState ? locationState.inUseStoreNumbers : undefined; // If this field is missing, then the inUseStoreNumbers state will remain an empty set
        const inUseSlugs = locationState ? locationState.inUseSlugs : undefined; // If this field is missing, then the inUseSlugs state will remain an empty set

        if (!nro && !this._isNewNRO()) {
            this.setState({ isLoading: false });
            return;
        }

        nros.getNROStores()
            .then(resp => {
                this.setState({
                    storeOptions: resp.stores,
                });
            })
            .catch(err => {
                logger.warn(err);
                this.setState({ showAlertModal: true, errorMsg: strings.storeOptionsErrorMsg });
            });

        if (nro) {
            this.setState({
                nroName: nro.name,
                storeId: nro.store.id,
                storeNumber: nro.store.storeNumber,
                storeName: nro.store.name,
                storeAddress: this._formatAddress(nro.store.address),
                headerText: nro.headerText,
                registerParagraphText: nro.registerParagraphText,
                loginParagraphText: nro.loginParagraphText,
                loggedInParagraphText: nro.loggedInParagraphText,
                completionText: nro.completionText,
                slug: nro.slug,
                isActive: nro.isActive,
            });
        }

        this.setState({
            ...(!!inUseNames && { inUseNames }),
            ...(!!inUseStoreNumbers && { inUseStoreNumbers }),
            ...(!!inUseSlugs && { inUseSlugs }),
        });

        if (this._isNewNRO()) {
            document.title = `${strings.newNRO} ${strings.titleSuffix}`;
            this.setState({ isLoading: false });
            return;
        }

        document.title = `${nro.name} ${strings.titleSuffix}`;
        this.setState({
            isLoading: false,
            nro,
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleImageOnSubmit = (imageCategory, image, crop, imageData) => {
        this.setState({
            [imageCategory]: {
                image,
                centerX: crop.x,
                centerY: crop.y,
                width: crop.width,
                height: crop.height,
                imageData: imageData,
            },
        });
    };

    _handleSavePage = () => {
        this.setState({ isLoading: true });
        if (this.state.nro && !this._isNewNRO()) {
            const editedNRO = {
                ...(this.state.nro.name !== this.state.nroName && {
                    name: this.state.nroName.trim(),
                }),
                ...(this.state.nro.storeId !== this.state.storeId && {
                    storeId: this.state.storeId,
                }),
                ...(this.state.nro.headerText !== this.state.headerText && {
                    headerText: this.state.headerText.trim(),
                }),
                ...(this.state.nro.registerParagraphText !== this.state.registerParagraphText && {
                    registerParagraphText: this.state.registerParagraphText.trim(),
                }),
                ...(this.state.nro.loginParagraphText !== this.state.loginParagraphText && {
                    loginParagraphText: this.state.loginParagraphText.trim(),
                }),
                ...(this.state.nro.loggedInParagraphText !== this.state.loggedInParagraphText && {
                    loggedInParagraphText: this.state.loggedInParagraphText.trim(),
                }),
                ...(this.state.nro.completionText !== this.state.completionText && {
                    completionText: this.state.completionText.trim(),
                }),
                ...(this.state.nro.slug !== this.state.slug && {
                    slug: this.state.slug.trim(),
                }),
                ...(this.state.nro.isActive !== this.state.isActive && {
                    isActive: this.state.isActive,
                }),
                ...(!!this.state.desktopImage && {
                    desktopImage: this.state.desktopImage,
                }),
                ...(!!this.state.tabletImage && { tabletImage: this.state.tabletImage }),
                ...(!!this.state.mobileImage && { mobileImage: this.state.mobileImage }),
            };
            return nros
                .editNRO(this.state.nro.id, editedNRO)
                .then(resp => {
                    if (this._isMounted) {
                        this.setState({ isLoading: false });
                        this.props.history.push(strings.nrosUrl);
                    }
                    return resp;
                })
                .catch(e => {
                    logger.warn(e);
                    let errorMsg = strings.generalErrorMsg;
                    if (
                        e &&
                        e.response &&
                        e.response.status === 400 &&
                        e.response.data &&
                        typeof e.response.data === "string"
                    ) {
                        errorMsg = e.response.data;
                    }
                    this.setState({ isLoading: false, showAlertModal: true, errorMsg });
                });
        }

        return nros
            .createNRO({
                name: this.state.nroName.trim(),
                storeId: this.state.storeId,
                headerText: this.state.headerText.trim(),
                registerParagraphText: this.state.registerParagraphText.trim(),
                loginParagraphText: this.state.loginParagraphText.trim(),
                loggedInParagraphText: this.state.loggedInParagraphText.trim(),
                completionText: this.state.completionText.trim(),
                slug: this.state.slug.trim(),
                isActive: this.state.isActive,
                desktopImage: this.state.desktopImage,
                tabletImage: this.state.tabletImage,
                mobileImage: this.state.mobileImage,
            })
            .then(resp => {
                if (this._isMounted) {
                    this.setState({ isLoading: false });
                    this.props.history.push(strings.nrosUrl);
                }
                return resp;
            })
            .catch(e => {
                logger.warn(e);
                let errorMsg = strings.generalErrorMsg;
                if (
                    e &&
                    e.response &&
                    e.response.status === 400 &&
                    e.response.data &&
                    typeof e.response.data === "string"
                ) {
                    errorMsg = e.response.data;
                }
                this.setState({ isLoading: false, showAlertModal: true, errorMsg });
            });
    };

    _showDuplicateModal = () => this.setState({ showDuplicateModal: true });

    _handleCloseDuplicateModal = () =>
        this.setState({
            showDuplicateModal: false,
            duplicateNROName: "",
            duplicateStoreNumber: "",
            duplicateStore: null,
            duplicateSlug: "",
        });

    _handleDuplicate = () => {
        if (this._isMounted) {
            this.props.history.push("/nro/new", {
                nro: {
                    store: this.state.duplicateStore,
                    name: this.state.duplicateNROName.trim(),
                    headerText: this.state.headerText.trim(),
                    registerParagraphText: this.state.registerParagraphText.trim(),
                    loginParagraphText: this.state.loginParagraphText.trim(),
                    loggedInParagraphText: this.state.loggedInParagraphText.trim(),
                    completionText: this.state.completionText.trim(),
                    isActive: this.state.isActive,
                    slug: this.state.duplicateSlug.trim(),
                },
                inUseNames: this.state.inUseNames,
                inUseStoreNumbers: this.state.inUseStoreNumbers,
                inUseSlugs: this.state.inUseSlugs,
            });
        }
    };

    _getNameInUse = name =>
        this.state.inUseNames.has(name) &&
        (!this.state.nro || (!!this.state.nro && this.state.nro.name !== name));
    _getIsValidName = value => !!value && !this._getNameInUse(value);
    _getSlugInUse = slug =>
        this.state.inUseSlugs.has(slug) &&
        (!this.state.nro || (!!this.state.nro && this.state.nro.slug !== slug));
    _getIsValidSlug = value => !!value && !this._getSlugInUse(value);
    _refineSlug = value => value.replace(/\s/g, "").toLowerCase();

    _getDuplicateNameInUse = value => this.state.inUseNames.has(value);
    _getIsValidDuplicateName = value => !!value && !this._getDuplicateNameInUse(value);
    _getDuplicateSlugInUse = value => this.state.inUseSlugs.has(value);
    _getIsValidDuplicateSlug = value => !!value && !this._getDuplicateSlugInUse(value);

    _updateStoreSelection = storeNumberStr => {
        const storeNumber = parseInt(storeNumberStr);
        if (!storeNumber) {
            this.setState({ storeId: "", storeNumber: "", storeName: "", storeAddress: "" });
            return;
        }

        const store = this.state.storeOptions.find(s => s.storeNumber === storeNumber);
        if (store) {
            this.setState({
                storeId: store.id,
                storeNumber: store.storeNumber,
                storeName: store.name,
                storeAddress: this._formatAddress(store.address),
            });
        }
    };

    _updateDuplicateStoreSelection = storeNumberStr => {
        const storeNumber = parseInt(storeNumberStr);
        if (!storeNumber) {
            this.setState({ duplicateStoreNumber: "", duplicateStore: null });
        }

        const store = this.state.storeOptions.find(s => s.storeNumber === storeNumber);
        if (store) {
            this.setState({
                duplicateStoreNumber: store.storeNumber,
                duplicateStore: store,
            });
        }
    };

    _compareStore = (a, b) => {
        return a.storeNumber - b.storeNumber;
    };

    render() {
        if (!this.state.nro && !this._isNewNRO()) {
            return <FailedToLoadAlert type={strings.nro} />;
        }

        const filteredStoreOptions = this.state.storeOptions.filter(
            store =>
                !this.state.inUseStoreNumbers.has(store.storeNumber) ||
                (this.state.nro && this.state.nro.store.storeNumber === store.storeNumber),
        );
        filteredStoreOptions.sort(this._compareStore);
        const filteredDuplicateStoreOptions = this.state.storeOptions.filter(
            store => !this.state.inUseStoreNumbers.has(store.storeNumber),
        );
        filteredDuplicateStoreOptions.sort(this._compareStore);

        const nro = this.state.nro;

        const isInputValid =
            this._getIsValidName(this.state.nroName.trim()) &&
            !!this.state.storeId &&
            !!this.state.headerText.trim() &&
            !!this.state.registerParagraphText.trim() &&
            !!this.state.loginParagraphText.trim() &&
            !!this.state.loggedInParagraphText.trim() &&
            !!this.state.completionText.trim() &&
            this._getIsValidSlug(this.state.slug.trim()) &&
            !!(this.state.desktopImage || (nro && nro.desktopImages)) &&
            !!(this.state.tabletImage || (nro && nro.tabletImages)) &&
            !!(this.state.mobileImage || (nro && nro.mobileImages));

        // isChanged is not useful if creating a new page, can potentially be both true and false depending on if duplicate
        const isChanged =
            !!nro &&
            !!(
                nro.name !== this.state.nroName.trim() ||
                nro.store.id !== this.state.storeId ||
                nro.headerText !== this.state.headerText.trim() ||
                nro.registerParagraphText !== this.state.registerParagraphText.trim() ||
                nro.loginParagraphText !== this.state.loginParagraphText.trim() ||
                nro.loggedInParagraphText !== this.state.loggedInParagraphText.trim() ||
                nro.completionText !== this.state.completionText.trim() ||
                nro.slug !== this.state.slug.trim() ||
                nro.isActive !== this.state.isActive ||
                !!this.state.desktopImage ||
                !!this.state.tabletImage ||
                !!this.state.mobileImage
            );

        const isDuplicateModalEnabled = !this.state.isLoading && !this._isNewNRO() && !isChanged;
        const isSavePageEnabled =
            !this.state.isLoading && isInputValid && (isChanged || this._isNewNRO());

        const isDuplicateEnabled =
            this._getIsValidDuplicateName(this.state.duplicateNROName.trim()) &&
            !!this.state.duplicateStore &&
            this._getIsValidDuplicateSlug(this.state.duplicateSlug.trim());

        const fullLandingPageUrl = landingPages.getFullLandingPageUrl(
            strings.nroUrl,
            this.state.slug.trim(),
        );

        return (
            <Grid>
                <LandingPageCrumbEditHeader
                    history={this.props.history}
                    landingPagesLinkText={strings.nroLandingPages}
                    landingPagesLinkUrl={strings.nrosUrl}
                    pageTitle={nro ? nro.name : strings.newNRO}
                    isDuplicateEnabled={isDuplicateModalEnabled}
                    onDuplicate={this._showDuplicateModal}
                    isSavePageEnabled={isSavePageEnabled}
                    onSavePage={this._handleSavePage}
                />
                <form>
                    <Row>
                        <Col md={5}>
                            <TextInputWithDuplicateCheck
                                label={strings.nroName}
                                placeholder={strings.nroNamePlaceholder}
                                value={this.state.nroName}
                                onChange={e => this.setState({ nroName: e.target.value })}
                                inUseValues={this.state.inUseNames}
                                currentValue={this.state.nro ? this.state.nro.name : ""}
                                isValid={this._getIsValidName(this.state.nroName)}
                                disabled={this.state.isLoading}
                            />
                            <FormGroup controlId="store-number">
                                <ControlLabel>{strings.storeNumber}</ControlLabel>
                                <StyledDropdown
                                    id="store-number"
                                    menuStyle={styles.storeNumberDropdownMenu}
                                    placeholder={strings.storeNumberPlaceholder}
                                    value={this.state.storeNumber}
                                    isFilterable
                                    onChange={storeNumber =>
                                        this._updateStoreSelection(storeNumber)
                                    }
                                    options={filteredStoreOptions.map(store => ({
                                        value: store.storeNumber,
                                    }))}
                                />
                            </FormGroup>
                            <FormGroup controlId="store-name">
                                <ControlLabel>{strings.storeName}</ControlLabel>
                                <FormControl type="text" value={this.state.storeName} disabled />
                            </FormGroup>
                            <FormGroup controlId="store-address">
                                <ControlLabel>{strings.storeAddress}</ControlLabel>
                                <FormControl type="text" value={this.state.storeAddress} disabled />
                            </FormGroup>
                            <FormGroup controlId="header-text">
                                <ControlLabel>{strings.headerText}</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.headerText}
                                    placeholder={strings.headerTextPlaceholder}
                                    onChange={e => this.setState({ headerText: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup controlId="register-paragraph-text">
                                <ControlLabel>{strings.registerParagraphText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.registerParagraphText}
                                    placeholder={strings.registerParagraphTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ registerParagraphText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <FormGroup controlId="login-paragraph-text">
                                <ControlLabel>{strings.loginParagraphText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.loginParagraphText}
                                    placeholder={strings.loginParagraphTextPlaceholder}
                                    onChange={e =>
                                        this.setState({
                                            loginParagraphText: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>
                            <FormGroup controlId="logged-in-paragraph-text">
                                <ControlLabel>{strings.loggedInParagraphText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.loggedInParagraphText}
                                    placeholder={strings.loggedInParagraphTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ loggedInParagraphText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <FormGroup controlId="completion-text">
                                <ControlLabel>{strings.completionText}</ControlLabel>
                                <FormControl
                                    className={css(styles.textArea)}
                                    componentClass="textarea"
                                    value={this.state.completionText}
                                    placeholder={strings.completionTextPlaceholder}
                                    onChange={e =>
                                        this.setState({ completionText: e.target.value })
                                    }
                                />
                            </FormGroup>
                            <TextInputWithDuplicateCheck
                                style={styles.slugFormGroup}
                                helpTextStyle={styles.slugHelpText}
                                label={strings.slug}
                                placeholder={strings.slugPlaceholder}
                                value={this.state.slug}
                                onChange={e =>
                                    this.setState({
                                        slug: this._refineSlug(e.target.value),
                                    })
                                }
                                inUseValues={this.state.inUseSlugs}
                                currentValue={this.state.nro ? this.state.nro.slug : ""}
                                isValid={this._getIsValidSlug(this.state.slug)}
                                disabled={this.state.isLoading}
                            />
                            <div className={css(styles.copySlugContainer)}>
                                <span className={css(styles.fullLandingPageUrl)}>
                                    {fullLandingPageUrl}
                                </span>
                                <Button
                                    bsStyle={"link"}
                                    className={css(styles.copyUrlButton)}
                                    onClick={() => util.copyToClipboard(fullLandingPageUrl)}
                                >
                                    {strings.copyUrl}
                                </Button>
                            </div>
                            <FormGroup>
                                <Checkbox
                                    className={css(styles.isActiveCheckbox)}
                                    checked={this.state.isActive}
                                    onChange={() =>
                                        this.setState({
                                            isActive: !this.state.isActive,
                                        })
                                    }
                                    disabled={this.state.isLoading}
                                >
                                    {strings.activeCheckboxLabel}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                        <Col md={6} mdPush={1}>
                            <div className={css(styles.imageUploadContainer)}>
                                <ImageSetUploader
                                    label={strings.desktopImageLabel}
                                    minWidth={728}
                                    minHeight={1150}
                                    onSubmit={(image, crop, imageData) =>
                                        this._handleImageOnSubmit(
                                            "desktopImage",
                                            image,
                                            crop,
                                            imageData,
                                        )
                                    }
                                    images={
                                        this.state.nro && !this.state.desktopImage
                                            ? this.state.nro.desktopImages
                                            : null
                                    }
                                    previewImage={
                                        this.state.desktopImage
                                            ? this.state.desktopImage.imageData
                                            : null
                                    }
                                />
                            </div>
                            <div className={css(styles.imageUploadContainer)}>
                                <ImageSetUploader
                                    label={strings.tabletImageLabel}
                                    minWidth={1280}
                                    minHeight={358}
                                    onSubmit={(image, crop, imageData) =>
                                        this._handleImageOnSubmit(
                                            "tabletImage",
                                            image,
                                            crop,
                                            imageData,
                                        )
                                    }
                                    images={
                                        this.state.nro && !this.state.tabletImage
                                            ? this.state.nro.tabletImages
                                            : null
                                    }
                                    previewImage={
                                        this.state.tabletImage
                                            ? this.state.tabletImage.imageData
                                            : null
                                    }
                                />
                            </div>
                            <div className={css(styles.imageUploadContainer)}>
                                <ImageSetUploader
                                    label={strings.mobileImageLabel}
                                    minWidth={768}
                                    minHeight={358}
                                    onSubmit={(image, crop, imageData) =>
                                        this._handleImageOnSubmit(
                                            "mobileImage",
                                            image,
                                            crop,
                                            imageData,
                                        )
                                    }
                                    images={
                                        this.state.nro && !this.state.mobileImage
                                            ? this.state.nro.mobileImages
                                            : null
                                    }
                                    previewImage={
                                        this.state.mobileImage
                                            ? this.state.mobileImage.imageData
                                            : null
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </form>
                <DuplicateLandingPageModal
                    show={this.state.showDuplicateModal}
                    itemName={strings.nro}
                    onClose={this._handleCloseDuplicateModal}
                    onDuplicate={this._handleDuplicate}
                    duplicateDisabled={!isDuplicateEnabled}
                >
                    <TextInputWithDuplicateCheck
                        label={strings.nroName}
                        placeholder={strings.nroNamePlaceholder}
                        value={this.state.duplicateNROName}
                        onChange={e => this.setState({ duplicateNROName: e.target.value })}
                        inUseValues={this.state.inUseNames}
                        isValid={this._getIsValidDuplicateName(this.state.duplicateNROName)}
                        disabled={this.state.isLoading}
                    />
                    <FormGroup controlId="duplicate-store-number">
                        <ControlLabel>{strings.storeNumber}</ControlLabel>
                        <StyledDropdown
                            id="duplicate-store-number"
                            menuStyle={styles.storeNumberDropdownMenu}
                            placeholder={strings.storeNumberPlaceholder}
                            value={this.state.duplicateStoreNumber}
                            isFilterable
                            onChange={storeNumber =>
                                this._updateDuplicateStoreSelection(storeNumber)
                            }
                            options={filteredDuplicateStoreOptions.map(store => ({
                                value: store.storeNumber,
                            }))}
                        />
                    </FormGroup>
                    <TextInputWithDuplicateCheck
                        helpTextStyle={styles.slugHelpText}
                        label={strings.slug}
                        placeholder={strings.slugPlaceholder}
                        value={this.state.duplicateSlug}
                        onChange={e =>
                            this.setState({
                                duplicateSlug: this._refineSlug(e.target.value),
                            })
                        }
                        inUseValues={this.state.inUseSlugs}
                        isValid={this._getIsValidDuplicateSlug(this.state.duplicateSlug)}
                        disabled={this.state.isLoading}
                    />
                </DuplicateLandingPageModal>
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={this.state.errorMsg}
                    handleClose={() => this.setState({ showAlertModal: false, errorMsg: "" })}
                />
                {this.state.isLoading ? (
                    <div className={css(styles.loadingContainer)}>
                        <Loader />
                    </div>
                ) : null}
            </Grid>
        );
    }
}

export default NROPage;
