import React, { PureComponent } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const strings = {
    duplicate: "Duplicate",
    cancel: "Cancel",
};

class DuplicateLandingPageModal extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        itemName: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        onDuplicate: PropTypes.func.isRequired,
        duplicateDisabled: PropTypes.bool.isRequired,
        children: PropTypes.node,
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${strings.duplicate} ${this.props.itemName}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onClose}>{strings.cancel}</Button>
                    <Button
                        bsStyle={"primary"}
                        onClick={this.props.onDuplicate}
                        disabled={this.props.duplicateDisabled}
                    >
                        {strings.duplicate}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DuplicateLandingPageModal;
