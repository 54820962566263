import config from "../../config.json";
import instance from "./instance";

const responseData = response => response.data;

// If timeout is 0, Axios treats it as no timeout being set
const IMPORT_STORE_MENUS_TIMEOUT_MS = !isNaN(parseInt(config.importStoreMenusTimeoutMs))
    ? parseInt(config.importStoreMenusTimeoutMs)
    : 0;

export function getStores(start, end, filter) {
    let url = `/cms/stores?start=${start}&end=${end}`;
    if (filter) {
        url += `&filter=${filter}`;
    }
    return instance
        .request({
            url,
            method: "GET",
        })
        .then(responseData);
}

export function getStore(storeId) {
    return instance
        .request({
            url: `/cms/stores/${storeId}`,
            method: "GET",
        })
        .then(responseData);
}

export function addImage(storeId, image, crop) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("centerX", crop.x);
    formData.append("centerY", crop.y);
    formData.append("width", crop.width);
    formData.append("height", crop.height);
    return instance
        .request({
            url: `/cms/stores/${storeId}/image`,
            method: "POST",
            data: formData,
        })
        .then(responseData);
}

export function featureProduct(storeId, featuredProductId) {
    return instance
        .request({
            url: `/cms/stores/${storeId}/featured`,
            method: "POST",
            data: {
                featuredProductId,
            },
        })
        .then(responseData);
}

export function deleteFeaturedProduct(storeId, featuredProductId) {
    return instance
        .request({
            url: `/cms/stores/${storeId}/featured/${featuredProductId}`,
            method: "DELETE",
        })
        .then(responseData);
}

export function reorderFeaturedProduct(storeId, featuredProductId, position) {
    return instance
        .request({
            url: `/cms/stores/${storeId}/featured/${featuredProductId}/reorder`,
            method: "POST",
            data: {
                position,
            },
        })
        .then(responseData);
}

export function updateProduct(storeId, productId, productType, status, statusReason) {
    return instance
        .request({
            url: `/cms/stores/${storeId}/${productType}/${productId}`,
            method: "POST",
            data: {
                status,
                statusReason,
            },
        })
        .then(responseData);
}

export function updateModifier(storeId, modifierId, status) {
    return instance
        .request({
            url: `/cms/stores/${storeId}/modifier/${modifierId}`,
            method: "POST",
            data: {
                status,
            },
        })
        .then(responseData);
}

export function importStoreMenus(storeNumbers, emails, importAll) {
    return instance.request({
        url: `/cms/stores/import`,
        method: "POST",
        timeout: IMPORT_STORE_MENUS_TIMEOUT_MS,
        data: {
            storeNumbers,
            emails,
            importAll,
        },
    });
}

export function getUnRenumberedStores() {
    return instance
        .request({
            url: `/cms/stores/unrenumbered-stores`,
            method: "GET",
        })
        .then(responseData);
}

export function getRenumbers() {
    return instance
        .request({
            url: `/cms/stores/renumbers`,
            method: "GET",
        })
        .then(responseData);
}

export function deleteRenumbering(storeNumber) {
    return instance.request({
        url: `/cms/stores/unrenumber-store`,
        method: "POST",
        data: {
            storeNumber,
        },
    });
}

export function addRenumbering(oldNumber, newNumber) {
    return instance.request({
        url: `/cms/stores/renumber-store`,
        method: "POST",
        data: {
            oldNumber,
            newNumber,
        },
    });
}

export function bulkAssignCampaign(campaignId, isDefault, storeIds) {
    return instance.request({
        url: `/cms/stores/assign-campaign`,
        method: "POST",
        data: {
            campaignId,
            isDefault,
            storeIds,
        },
    });
}

export function bulkUnassignCampaign(unassignDefault, storeIds) {
    return instance.request({
        url: "/cms/stores/unassign-campaign",
        method: "POST",
        data: {
            unassignDefault,
            storeIds,
        },
    });
}

export function unassignCampaign(storeId, isDefault) {
    return instance.request({
        url: `/cms/stores/${storeId}/unassign-campaign`,
        method: "PUT",
        data: {
            isDefault,
        },
    });
}

export function getCampaignAssignments() {
    return instance
        .request({
            url: `/cms/stores/campaign-assignments`,
            method: "GET",
        })
        .then(responseData);
}
