/* eslint-disable react/jsx-no-bind */

import * as campaigns from "../../lib/api/campaigns";
import * as campaignType from "../../enums/campaignType";
import * as color from "../../styles/color";
import * as stores from "../../lib/api/stores";
import AlertModal from "../AlertModal";
import AssignCampaignModal from "../AssignCampaignModal";
import ConfirmationModal from "../ConfirmationModal";
import dateFns from "date-fns";
import FailedToLoadAlert from "../FailedToLoadAlert";
import Loader from "../Loader";
import logger from "../../lib/logger";
import React, { PureComponent } from "react";
import RemoveCampaignModal from "../RemoveCampaignModal";
import UploadStoresModal from "../UploadStoresModal";
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Row,
    Table,
} from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const CHECKBOX_SIZE = 18;

const styles = StyleSheet.create({
    loadingContainer: {
        position: "absolute",
        top: "50px",
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: color.whiteRgb3,
        display: "flex",
        alignItems: "center",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "16px",
    },
    filterContainer: {
        width: "415px",
        marginBottom: 0,
    },
    headerButtonsContainer: {
        display: "flex",
        gap: "21px",
        alignItems: "center",
    },
    tableHeaderRow: {
        fontSize: "16px",
    },
    storeRow: {
        cursor: "pointer",
        fontSize: "16px",
    },
    storeRowSelected: {
        backgroundColor: color.lightBlue,
    },
    checkboxCell: {
        width: "5%",
        padding: 0,
        cursor: "pointer",
    },
    checkboxHeaderCell: {
        paddingBottom: "6px",
    },
    checkbox: {
        cursor: "pointer",
        width: `${CHECKBOX_SIZE}px`,
        height: `${CHECKBOX_SIZE}px`,
        marginLeft: `calc(50% - ${CHECKBOX_SIZE / 2}px)`,
        marginTop: "10px",
    },
    tableCell: {
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    storeLabelCell: {
        width: "25%",
    },
    storeLabel: {
        cursor: "pointer",
        margin: 0,
        fontWeight: "400",
    },
    campaignCol: {
        width: "35%",
    },
});

const strings = {
    documentTitle: "Campaign Locations | Habit CMS",
    pageTitle: "Assign Stores to Campaigns",
    filterLabel: "Search",
    filterPlaceholder: "Search by store name or store number",
    removeCampaign: "Remove Campaign",
    uploadListButtonLabel: "Upload List",
    assignCampaignButtonLabel: "Assign Campaign",
    storeNameColHeader: "Stores",
    defaultCampaignColHeader: "Default Campaign",
    activeCampaignColHeader: "Active Campaign",
    storeTable: "Store Table or Campaigns List",
    confirmMsg:
        "Stores not visible on the screen were selected. Any stores that are not visible will not be affected. To target all selected stores, please clear all filters. Would you like to target only the selected and visible stores?",
    failedToAssignMsg: "There was an error while assigning the campaign.",
    failedToUnassignMsg: selectedCampaignType =>
        `There was an error unassigning the ${selectedCampaignType} campaign from the selected stores.`,
};

class AssignCampaignsPage extends PureComponent {
    static propTypes = {};

    state = {
        isLoading: false,
        filter: "",
        stores: [],
        campaigns: [],
        selectedStoreIds: new Set(),
        showRemoveCampaignModal: false,
        showUploadStoresModal: false,
        showConfirmModal: false,
        showAssignCampaignModal: false,
        showFailedToLoadAlert: false,
        showAlertModal: false,
        alertMsg: "",
    };

    componentDidMount() {
        this._loadData();
    }

    _loadData = () => {
        this.setState({ isLoading: true });

        Promise.all([stores.getCampaignAssignments(), campaigns.getCampaigns()])
            .then(([storesResponse, campaignsResponse]) => {
                this.setState(() => {
                    const newStores = storesResponse.campaignAssignments.map(ca => {
                        return {
                            id: ca.storeId,
                            name: ca.storeName,
                            storeNumber: ca.storeNumber,
                            defaultCampaignId: ca.defaultCampaignId,
                            activeCampaignId: ca.activeCampaignId,
                        };
                    });
                    newStores.sort((a, b) => a.storeNumber - b.storeNumber);

                    const newCampaigns = [...campaignsResponse.campaigns];
                    newCampaigns.sort((a, b) => a.name.localeCompare(b.name));

                    return {
                        stores: newStores,
                        campaigns: newCampaigns,
                    };
                });
            })
            .catch(e => {
                logger.warn(e);
                this.setState({
                    showFailedToLoadAlert: true,
                });
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    _handleClickConfirmUnassign = () => {
        this.setState({
            showConfirmModal: false,
            showRemoveCampaignModal: true,
        });
    };

    _handleClickConfirmAssign = () => {
        this.setState({
            showConfirmModal: false,
            showAssignCampaignModal: true,
        });
    };

    _handleConfirmFunc = this._handleClickConfirmAssign;

    _handleChangeFilter = e => {
        this.setState({ filter: e.target.value });
    };

    _getFilteredStores = () => {
        const loweredFilterText = this.state.filter.toLowerCase().trim();
        const filteredStores = this.state.stores.filter(
            store =>
                store.name.toLowerCase().indexOf(loweredFilterText) !== -1 ||
                store.storeNumber.toString().indexOf(loweredFilterText) !== -1,
        );
        return filteredStores;
    };

    _handleClickSelectAllCell = () => {
        this.setState(prevState => {
            const filteredStores = this._getFilteredStores();
            if (filteredStores.length === 0) {
                return;
            }

            const newSelectedStoreIds = new Set(prevState.selectedStoreIds);
            const allSelected =
                filteredStores.findIndex(store => !newSelectedStoreIds.has(store.id)) === -1;

            filteredStores.forEach(store => {
                if (allSelected) {
                    newSelectedStoreIds.delete(store.id);
                } else {
                    newSelectedStoreIds.add(store.id);
                }
            });

            return { selectedStoreIds: newSelectedStoreIds };
        });
    };

    _toggleSelectStoreById = storeId => {
        this.setState(prevState => {
            const newSelectedStoreIds = new Set(prevState.selectedStoreIds);
            if (newSelectedStoreIds.has(storeId)) {
                newSelectedStoreIds.delete(storeId);
            } else {
                newSelectedStoreIds.add(storeId);
            }

            return {
                selectedStoreIds: newSelectedStoreIds,
            };
        });
    };

    _handleClickStoreRow = e => {
        const storeId = e.target.parentNode.dataset.storeId;
        if (storeId) {
            this._toggleSelectStoreById(storeId);
        }
    };

    _handleChangeCheckbox = e => {
        e.stopPropagation();
        const storeId = e.target.dataset.storeId;
        if (storeId) {
            this._toggleSelectStoreById(storeId);
        }
    };

    _handleClickRemoveCampaign = () => {
        const filteredStores = this._getFilteredStores();
        const selectedFilteredStoreIds = filteredStores
            .filter(store => this.state.selectedStoreIds.has(store.id))
            .map(store => store.id);

        const isNonVisibleStoreSelected =
            selectedFilteredStoreIds.length !== this.state.selectedStoreIds.size;

        if (isNonVisibleStoreSelected) {
            this.setState({ showConfirmModal: true });
            this._handleConfirmFunc = this._handleClickConfirmUnassign;
            return;
        }

        this.setState({ showRemoveCampaignModal: true });
    };

    _handleCancelRemoveCampaign = () => {
        this.setState({
            showRemoveCampaignModal: false,
        });
    };

    _handleRemoveCampaign = async selectedCampaignType => {
        this.setState({ isLoading: true });

        const filteredStores = this._getFilteredStores();
        const selectedFilteredStoreIds = filteredStores
            .filter(store => this.state.selectedStoreIds.has(store.id))
            .map(store => store.id);

        stores
            .bulkUnassignCampaign(
                selectedCampaignType === campaignType.defaultCampaign.value,
                selectedFilteredStoreIds,
            )
            .then(() => {
                this.setState({
                    showRemoveCampaignModal: false,
                });
                this._loadData(); // TODO: should we manage the updated state locally instead of triggering download resulting in fresh API calls?
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    showAlertModal: true,
                    alertMsg: strings.failedToUnassignMsg(selectedCampaignType),
                });
            });
    };

    _showUploadStoresModal = () => {
        this.setState({ showUploadStoresModal: true });
    };

    _hideUploadStoresModal = () => {
        this.setState({
            showUploadStoresModal: false,
        });
    };

    _selectStoresWithList = storeNumbers => {
        this.setState(prevState => {
            const newSelectedStoreIds = new Set(prevState.selectedStoreIds);

            this.state.stores.forEach(store => {
                if (storeNumbers.has(store.storeNumber)) {
                    newSelectedStoreIds.add(store.id);
                }
            });

            return {
                showUploadStoresModal: false,
                selectedStoreIds: newSelectedStoreIds,
            };
        });
    };

    _hideConfirmModal = () => {
        this.setState({
            showConfirmModal: false,
        });
    };

    _handleClickAssignCampaign = () => {
        const filteredStores = this._getFilteredStores();
        const selectedFilteredStoreIds = filteredStores
            .filter(store => this.state.selectedStoreIds.has(store.id))
            .map(store => store.id);

        const isNonVisibleStoreSelected =
            selectedFilteredStoreIds.length !== this.state.selectedStoreIds.size;

        if (isNonVisibleStoreSelected) {
            this.setState({ showConfirmModal: true });
            this._handleConfirmFunc = this._handleClickConfirmAssign;
            return;
        }

        this.setState({ showAssignCampaignModal: true });
    };

    _hideAssignCampaignModal = () => {
        this.setState({ showAssignCampaignModal: false });
    };

    _handleAssignCampaign = (campaignId, isDefault) => {
        this.setState({ isLoading: true });

        const filteredStores = this._getFilteredStores();
        const selectedFilteredStoreIds = filteredStores
            .filter(store => this.state.selectedStoreIds.has(store.id))
            .map(store => store.id);

        stores
            .bulkAssignCampaign(campaignId, isDefault, selectedFilteredStoreIds)
            .then(() => {
                this.setState({ showAssignCampaignModal: false });
                this._loadData(); // TODO: should we manage the updated state locally instead of triggering download resulting in fresh API calls?
            })
            .catch(e => {
                logger.warn(e);
                this.setState({
                    isLoading: false,
                    showAlertModal: true,
                    alertMsg: strings.failedToAssignMsg,
                });
            });
    };

    _hideAlertModal = () => {
        this.setState({
            showAlertModal: false,
            alertMsg: "",
        });
    };

    _getActiveCampaignLabel = activeCampaign => {
        if (!activeCampaign) {
            return null;
        }

        if (!activeCampaign.startDate && !activeCampaign.endDate) {
            return activeCampaign.name;
        }

        if (!activeCampaign.endDate) {
            return `${activeCampaign.name} - (${dateFns.format(
                activeCampaign.startDate,
                "DD/MM/YYYY",
            )} - no end)`;
        }

        if (!activeCampaign.startDate) {
            return `${activeCampaign.name} - (no start - ${dateFns.format(
                activeCampaign.endDate,
                "DD/MM/YYYY",
            )})`;
        }

        return `${activeCampaign.name} - (${dateFns.format(
            activeCampaign.startDate,
            "MM/DD/YYYY",
        )} - ${dateFns.format(dateFns.parse(activeCampaign.endDate), "MM/DD/YYYY")})`;
    };

    render() {
        const filteredStores = this._getFilteredStores();
        const selectedFilteredStores = filteredStores.filter(store =>
            this.state.selectedStoreIds.has(store.id),
        );

        const allSelected =
            selectedFilteredStores.length > 0 &&
            filteredStores.length === selectedFilteredStores.length;
        const isStoreSelected = selectedFilteredStores.length > 0;

        const allStoreNumbers = this.state.stores.map(store => store.storeNumber);

        const activeCampaigns = this.state.campaigns.filter(campaign => campaign.isActive);

        return (
            <>
                <Grid>
                    <Row>
                        <Col md={12}>
                            <h3>{strings.pageTitle}</h3>

                            <div className={css(styles.headerContainer)}>
                                <FormGroup
                                    controlId="filterText"
                                    className={css(styles.filterContainer)}
                                >
                                    <ControlLabel>{strings.filterLabel}</ControlLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.filter}
                                        placeholder={strings.filterPlaceholder}
                                        onChange={this._handleChangeFilter}
                                    />
                                </FormGroup>
                                <div className={css(styles.headerButtonsContainer)}>
                                    {isStoreSelected ? (
                                        <Button
                                            bsStyle="danger"
                                            disabled={this.state.isLoading}
                                            onClick={this._handleClickRemoveCampaign}
                                        >
                                            {strings.removeCampaign}
                                        </Button>
                                    ) : null}
                                    <Button
                                        disabled={this.state.isLoading}
                                        onClick={this._showUploadStoresModal}
                                    >
                                        {strings.uploadListButtonLabel}
                                    </Button>
                                    <Button
                                        bsStyle="primary"
                                        disabled={this.state.isLoading || !isStoreSelected}
                                        onClick={this._handleClickAssignCampaign}
                                    >
                                        {strings.assignCampaignButtonLabel}
                                    </Button>
                                </div>
                            </div>
                            <Table striped bordered hover>
                                <thead>
                                    <tr className={css(styles.tableHeaderRow)}>
                                        <th
                                            className={css(
                                                styles.checkboxCell,
                                                styles.checkboxHeaderCell,
                                            )}
                                            onClick={this._handleClickSelectAllCell}
                                        >
                                            <input
                                                className={css(styles.checkbox)}
                                                type="checkbox"
                                                readOnly
                                                checked={allSelected}
                                            />
                                        </th>
                                        <th className={css(styles.tableCell)}>
                                            {strings.storeNameColHeader}
                                        </th>
                                        <th className={css(styles.tableCell)}>
                                            {strings.defaultCampaignColHeader}
                                        </th>
                                        <th className={css(styles.tableCell)}>
                                            {strings.activeCampaignColHeader}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStores.map(store => {
                                        const defaultCampaign = this.state.campaigns.find(
                                            c => c.id === store.defaultCampaignId,
                                        );
                                        const activeCampaign = this.state.campaigns.find(
                                            c => c.id === store.activeCampaignId,
                                        );
                                        return (
                                            <tr
                                                key={store.id}
                                                className={css(
                                                    styles.storeRow,
                                                    this.state.selectedStoreIds.has(store.id) &&
                                                        styles.storeRowSelected,
                                                )}
                                                data-store-id={store.id}
                                                onClick={this._handleClickStoreRow}
                                            >
                                                <td className={css(styles.checkboxCell)}>
                                                    <input
                                                        data-store-id={store.id}
                                                        className={css(styles.checkbox)}
                                                        type="checkbox"
                                                        checked={this.state.selectedStoreIds.has(
                                                            store.id,
                                                        )}
                                                        onChange={this._handleChangeCheckbox}
                                                    />
                                                </td>
                                                <td
                                                    className={css(
                                                        styles.tableCell,
                                                        styles.storeLabelCell,
                                                    )}
                                                >
                                                    {`${store.storeNumber} - ${store.name}`}
                                                </td>
                                                <td
                                                    className={css(
                                                        styles.tableCell,
                                                        styles.campaignCol,
                                                    )}
                                                >
                                                    {defaultCampaign ? defaultCampaign.name : null}
                                                </td>
                                                <td
                                                    className={css(
                                                        styles.tableCell,
                                                        styles.campaignCol,
                                                    )}
                                                >
                                                    {this._getActiveCampaignLabel(activeCampaign)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Grid>
                {this.state.isLoading ? (
                    <div className={css(styles.loadingContainer)}>
                        <Loader />
                    </div>
                ) : null}
                {this.state.showFailedToLoadAlert ? (
                    <Row>
                        <FailedToLoadAlert type={strings.storeTable} />
                    </Row>
                ) : null}
                <RemoveCampaignModal
                    show={this.state.showRemoveCampaignModal}
                    onClose={this._handleCancelRemoveCampaign}
                    onRemoveCampaign={this._handleRemoveCampaign}
                    stores={selectedFilteredStores}
                />
                <UploadStoresModal
                    show={this.state.showUploadStoresModal}
                    availableStoreNumbers={allStoreNumbers}
                    handleClose={this._hideUploadStoresModal}
                    handleSelectStoreNumbers={this._selectStoresWithList}
                />
                <ConfirmationModal
                    show={this.state.showConfirmModal}
                    prompt={strings.confirmMsg}
                    handleCancel={this._hideConfirmModal}
                    handleConfirm={this._handleConfirmFunc}
                />
                <AssignCampaignModal
                    show={this.state.showAssignCampaignModal}
                    handleClose={this._hideAssignCampaignModal}
                    handleAssignCampaign={this._handleAssignCampaign}
                    campaigns={activeCampaigns}
                />
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={this.state.alertMsg}
                    handleClose={this._hideAlertModal}
                />
            </>
        );
    }
}

export default AssignCampaignsPage;
