import * as color from "../styles/color";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ControlLabel, FormControl, FormGroup, HelpBlock } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    errorText: {
        color: color.red,
    },
});

const strings = {
    available: "Available",
    fieldInUse: fieldName =>
        `This ${fieldName} is already in use. Select a different ${fieldName}.`,
};

class TextInputWithDuplicateCheck extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        inputStyle: PropTypes.object,
        helpTextStyle: PropTypes.object,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        inUseValues: PropTypes.object.isRequired, // Must be a Set of strings
        currentValue: PropTypes.string,
        isValid: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
    };

    _isInUse = () => {
        return (
            this.props.inUseValues.has(this.props.value.trim()) &&
            (!this.props.currentValue ||
                (this.props.currentValue &&
                    this.props.currentValue !== this.props.value.trim()))
        );
    };

    _getHelpMsg = () => {
        if (this._isInUse()) {
            return strings.fieldInUse(this.props.label);
        }

        return this.props.isValid ? `${this.props.label} ${strings.available}` : "";
    };

    render() {
        const helpMsg = this._getHelpMsg();

        return (
            <FormGroup
                controlId={`duplicate-${this.props.label}`}
                className={css(this.props.style)}
            >
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl
                    className={css(this.props.inputStyle)}
                    type="text"
                    value={this.props.value}
                    placeholder={this.props.placeholder ? this.props.placeholder : ""}
                    onChange={this.props.onChange}
                    disabled={!!this.props.disabled}
                />
                <HelpBlock
                    className={css(this._isInUse() && styles.errorText, this.props.helpTextStyle)}
                >
                    {helpMsg}
                </HelpBlock>
            </FormGroup>
        );
    }
}

export default TextInputWithDuplicateCheck;
