import * as color from "../styles/color";
import ConfirmationModal from "./ConfirmationModal";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    crumbRow: {
        backgroundColor: color.lightGrey,
        marginTop: "-20px",
        marginBottom: "24px",
        marginLeft: "calc(-50vw + 50%)",
        marginRight: "calc(-50vw + 50%)",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "calc(50vw - 50% - 15px)",
        paddingRight: "calc(50vw - 50% - 15px)",
    },
    linkButton: {
        padding: 0,
        marginTop: "2px",
        marginBottom: "10px",
    },
    linkButtonText: {
        margin: 0,
    },
    slug: {
        display: "inline-block",
        marginTop: "10px",
        marginLeft: "4px",
        marginBottom: "10px",
    },
    duplicateButton: {
        width: "100%",
    },
    discardChangesButton: {
        width: "100%",
    },
    savePageButton: {
        width: "100%",
    },
});

const strings = {
    duplicate: "Duplicate",
    discardChanges: "Discard Changes",
    discardChangesPrompt:
        "You have unsaved changes for this campaign. Are you sure you want to discard them?",
    savePage: "Save Page",
};

class LandingPageCrumbEditHeader extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        landingPagesLinkText: PropTypes.string.isRequired,
        landingPagesLinkUrl: PropTypes.string.isRequired,
        pageTitle: PropTypes.string.isRequired,
        hideDuplicateButton: PropTypes.bool,
        isDuplicateEnabled: PropTypes.bool.isRequired,
        onDuplicate: PropTypes.func.isRequired,
        showDiscardChanges: PropTypes.bool,
        isSavePageEnabled: PropTypes.bool.isRequired,
        savePageButtonLabel: PropTypes.string,
        onSavePage: PropTypes.func.isRequired,
    };

    state = {
        showDiscardChangesModal: false,
    };

    _returnToListPage = () => {
        this.props.history.push(this.props.landingPagesLinkUrl);
    };

    _openDiscardChangesModal = () => {
        this.setState({
            showDiscardChangesModal: true,
        });
    };

    _handleClickListPageLinkButton = () => {
        if (this.props.showDiscardChanges) {
            this._openDiscardChangesModal();
            return;
        }

        this._returnToListPage();
    };

    _handleClickDiscardChanges = () => {
        this._openDiscardChangesModal();
    };

    _handleDiscardChangesModalCancel = () => {
        this.setState({
            showDiscardChangesModal: false,
        });
    };

    _handleDiscardChangesModalConfirm = () => {
        this.setState({
            showDiscardChangesModal: false,
        });
        this._returnToListPage();
    };

    render() {
        return (
            <>
                <Row className={css(styles.crumbRow)}>
                    <Col md={6}>
                        <Row>
                            <Button
                                className={css(styles.linkButton)}
                                bsStyle="link"
                                onClick={this._handleClickListPageLinkButton}
                            >
                                <h4
                                    className={css(styles.linkButtonText)}
                                >{`${this.props.landingPagesLinkText}`}</h4>
                            </Button>
                            <h4 className={css(styles.slug)}>{` / ${this.props.pageTitle}`}</h4>
                        </Row>
                    </Col>
                    {!this.props.hideDuplicateButton ? (
                        // Showing both "Duplicate" and "Discard Changes" button breaks styling
                        <Col md={2}>
                            <Button
                                bsStyle="default"
                                className={css(styles.duplicateButton)}
                                onClick={this.props.onDuplicate}
                                disabled={!this.props.isDuplicateEnabled}
                            >
                                {strings.duplicate}
                            </Button>
                        </Col>
                    ) : null}
                    {this.props.showDiscardChanges ? (
                        // Showing both "Duplicate" and "Discard Changes" button breaks styling
                        <Col md={2}>
                            <Button
                                bsStyle="danger"
                                className={css(styles.discardChangesButton)}
                                onClick={this._handleClickDiscardChanges}
                            >
                                {strings.discardChanges}
                            </Button>
                        </Col>
                    ) : null}
                    <Col md={4}>
                        <Button
                            bsStyle="primary"
                            className={css(styles.savePageButton)}
                            onClick={this.props.onSavePage}
                            disabled={!this.props.isSavePageEnabled}
                        >
                            {this.props.savePageButtonLabel
                                ? this.props.savePageButtonLabel
                                : strings.savePage}
                        </Button>
                    </Col>
                </Row>
                <ConfirmationModal
                    show={this.state.showDiscardChangesModal}
                    prompt={strings.discardChangesPrompt}
                    confirmButtonLabel={strings.discardChanges}
                    confirmButtonBsStyle={"danger"}
                    handleCancel={this._handleDiscardChangesModalCancel}
                    handleConfirm={this._handleDiscardChangesModalConfirm}
                />
            </>
        );
    }
}

export default LandingPageCrumbEditHeader;
