/* eslint-disable react/jsx-no-bind */

import * as campaigns from "../../lib/api/campaigns";
import * as color from "../../styles/color";
import AlertModal from "../AlertModal";
import CampaignBadge from "../CampaignBadge";
import DuplicateLandingPageModal from "../DuplicateLandingPageModal";
import FailedToLoadAlert from "../FailedToLoadAlert";
import LandingPageHeader from "../LandingPageHeader";
import Loader from "../Loader";
import logger from "../../lib/logger";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import TextInputWithDuplicateCheck from "../TextInputWithDuplicateCheck";
import { Button, Col, Grid, Row, Table } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    table: {
        marginTop: "5px",
    },
    nameColHeader: {
        width: "25%",
    },
    linkColHeader: {
        width: "20%",
    },
    linkCell: {
        padding: 0,
        position: "relative",
    },
    linkButton: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        textAlign: "left",
    },
    activeCol: {
        minWidth: "80px",
    },
});

const strings = {
    documentTitle: "Campaigns | Habit CMS",
    pageTitle: "Campaigns",
    filterByName: "Filter by Campaign Name:",
    campaignName: "Campaign Name",
    CampaignsUrl: "campaigns",
    editCampaignUrl: "/campaign",
    newSlug: "new",
    campaignTable: "Campaign Table",
    createCampaign: "Create Campaign",
    defaultStores: "Default Stores",
    nonDefaultStores: "Non-Default Stores",
    edit: "Edit",
    duplicate: "Duplicate",
    campaign: "Campaign",
    name: "Name",
    namePlaceholder: "Enter name",
    failedToDuplicateMsg:
        "There was an error retrieving information for this campaign. It could not be duplicated.",
    active: "Active",
    inactive: "Inactive",
};

class CampaignsPage extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    state = {
        campaigns: [],
        showInactiveCampaigns: false,
        filter: "",
        isLoading: false,
        isError: false,
        inUseNames: new Set(),
        showDuplicateModal: false,
        duplicateId: null,
        duplicateName: "",
        showAlertModal: false,
    };

    componentDidMount() {
        document.title = strings.documentTitle;
        this._loadCampaigns();
    }

    _loadCampaigns = () => {
        this.setState({ isLoading: true });

        campaigns
            .getCampaigns()
            .then(resp => {
                const newCampaigns = [...resp.campaigns];
                newCampaigns.sort((a, b) => a.name.localeCompare(b.name));

                this.setState({
                    isLoading: false,
                    campaigns: newCampaigns,
                    inUseNames: new Set(resp.campaigns.map(c => c.name)),
                });
            })
            .catch(e => {
                throw e;
            })
            .catch(e => {
                logger.warn(e);
                this.setState({ isLoading: false, isError: true });
            });
    };

    _handleChangeShowInactivePages = () => {
        this.setState(prevState => ({
            showInactiveCampaigns: !prevState.showInactiveCampaigns,
        }));
    };

    _showDuplicateModal = campaignId => {
        this.setState({ showDuplicateModal: true, duplicateId: campaignId });
    };

    _isValidDuplicateName = () => {
        return (
            this.state.duplicateName.trim() !== "" &&
            !this.state.inUseNames.has(this.state.duplicateName.trim())
        );
    };

    _handleChangeDuplicateName = e => {
        this.setState({ duplicateName: e.target.value });
    };

    _handleDuplicateCampaign = () => {
        if (!this.state.duplicateId) {
            return;
        }

        this.setState({ isLoading: true });

        campaigns
            .getCampaign(this.state.duplicateId)
            .then(campaignResp => {
                this.props.history.push(`${strings.editCampaignUrl}/${strings.newSlug}`, {
                    campaign: {
                        name: this.state.duplicateName.trim(),
                        isActive: campaignResp.isActive,
                        startDate: campaignResp.startDate,
                        endDate: campaignResp.endDate,
                    },
                    inUseNames: this.state.inUseNames,
                });
            })
            .catch(e => {
                logger.warn(e);
                this.setState({ isLoading: false, showAlertModal: true });
            });
    };

    _handleCloseDuplicateModal = () => {
        this.setState({ showDuplicateModal: false, duplicateId: null, duplicateName: "" });
    };

    _hideAlertModal = () => {
        this.setState({ showAlertModal: false });
    };

    render() {
        const loweredFilter = this.state.filter.toLowerCase().trim();
        const visibleCampaigns = this.state.campaigns.filter(c => {
            if (!c.isActive && !this.state.showInactiveCampaigns) {
                return false;
            }

            return c.name.toLowerCase().indexOf(loweredFilter) !== -1;
        });

        const isValidDuplicateName = this._isValidDuplicateName();

        return (
            <Grid>
                <LandingPageHeader
                    title={strings.pageTitle}
                    showInactivePages={this.state.showInactiveCampaigns}
                    onChangeShowInactivePages={this._handleChangeShowInactivePages}
                    filterLabel={strings.filterByName}
                    filter={this.state.filter}
                    onChangeFilter={e => this.setState({ filter: e.target.value })}
                    onAddPage={() =>
                        this.props.history.push(`${strings.editCampaignUrl}/${strings.newSlug}`, {
                            inUseNames: this.state.inUseNames,
                        })
                    }
                    buttonLabel={strings.createCampaign}
                />
                <Row>
                    <Col md={12}>
                        <Table striped bordered hover className={css(styles.table)}>
                            <thead>
                                <tr>
                                    <th className={css(styles.nameColHeader)}>
                                        {strings.campaignName}
                                    </th>
                                    <th>{strings.defaultStores}</th>
                                    <th>{strings.nonDefaultStores}</th>
                                    <th className={css(styles.linkColHeader)}>{strings.edit}</th>
                                    <th className={css(styles.linkColHeader)}>
                                        {strings.duplicate}
                                    </th>
                                    <th className={css(styles.activeCol)}>{strings.active}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {visibleCampaigns.map(item => {
                                    return (
                                        <tr key={`row-${item.id}`}>
                                            <td>{item.name}</td>
                                            <td>{item.defaultStoreCount}</td>
                                            <td>{item.nonDefaultStoreCount}</td>
                                            <td className={css(styles.linkCell)}>
                                                <Button
                                                    bsStyle={"link"}
                                                    className={css(styles.linkButton)}
                                                    onClick={() => {
                                                        this.props.history.push(
                                                            `${strings.editCampaignUrl}/${item.id}`,
                                                            { inUseNames: this.state.inUseNames },
                                                        );
                                                    }}
                                                >
                                                    {strings.edit}
                                                </Button>
                                            </td>
                                            <td className={css(styles.linkCell)}>
                                                <Button
                                                    bsStyle={"link"}
                                                    className={css(styles.linkButton)}
                                                    onClick={() =>
                                                        this._showDuplicateModal(item.id)
                                                    }
                                                >
                                                    {strings.duplicate}
                                                </Button>
                                            </td>
                                            <td>
                                                <CampaignBadge
                                                    color={item.isActive ? color.green : color.red}
                                                >
                                                    {item.isActive
                                                        ? strings.active
                                                        : strings.inactive}
                                                </CampaignBadge>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {this.state.isLoading ? <Loader /> : null}
                    </Col>
                </Row>
                {this.state.isError ? (
                    <Row>
                        <FailedToLoadAlert type={strings.campaignTable} />
                    </Row>
                ) : null}
                <DuplicateLandingPageModal
                    show={this.state.showDuplicateModal}
                    itemName={strings.campaign}
                    onClose={this._handleCloseDuplicateModal}
                    onDuplicate={this._handleDuplicateCampaign}
                    duplicateDisabled={!isValidDuplicateName}
                >
                    <TextInputWithDuplicateCheck
                        label={strings.name}
                        placeholder={strings.namePlaceholder}
                        value={this.state.duplicateName}
                        onChange={this._handleChangeDuplicateName}
                        inUseValues={this.state.inUseNames}
                        isValid={isValidDuplicateName}
                        disabled={this.state.isLoading}
                    />
                </DuplicateLandingPageModal>
                <AlertModal
                    show={this.state.showAlertModal}
                    alertMessage={strings.failedToDuplicateMsg}
                    handleClose={this._hideAlertModal}
                />
            </Grid>
        );
    }
}

export default CampaignsPage;
